import React, { Component } from 'react'

import ButtonLink from '../components/button-link';
import Layout from '../components/layout'
import PageSection from '../components/page-section'
import SectionTitle from '../components/section-title'


class ContactSuccessPage extends Component {
	render () {
		return (
			<Layout>

				<PageSection>
					<SectionTitle
						title="Tack"
					/>
					<p>
						Ansökan har skickats, och vi kommer att återkomma till dig inom kort.
					</p>

					<ButtonLink
						to="/om-oss/"
						color="#666"
						hoverColor="#fff"
					>Tillbaka</ButtonLink>
				</PageSection>

			</Layout>
		)
	}
}

export default ContactSuccessPage
